import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import axios from 'axios';
import $ from 'jquery';
import {
    // BrowserRouter as Router,
    Link
} from "react-router-dom";
import HeaderImg from '../assets/img/homepage/cover.jpeg';

import About1 from '../assets/img/gallery/photo_1.jpeg';
import About2 from '../assets/img/gallery/photo_2.jpeg';
import About3 from '../assets/img/gallery/photo_3.jpeg';
import About4 from '../assets/img/gallery/photo_4.jpeg';
import About5 from '../assets/img/gallery/photo_5.jpeg';
import About6 from '../assets/img/gallery/photo_6.jpeg';
import About7 from '../assets/img/gallery/photo_7.jpeg';
import About8 from '../assets/img/gallery/photo_8.jpeg';
import About9 from '../assets/img/gallery/photo_9.jpeg';
import About10 from '../assets/img/gallery/photo_10.jpeg';
import About11 from '../assets/img/gallery/photo_11.jpeg';
import About12 from '../assets/img/gallery/photo_12.jpeg';
import About13 from '../assets/img/gallery/photo_13.jpeg';

import Ispimg from '../assets/img/isp.jpg';
import BgImgGame from '../assets/img/bg-implica-te.jpg';
import { ReactComponent as Arrow } from '../assets/svg/arrow.svg';

function Homepage() {

    const [formData, setFormData] = useState({
        blogs: [],
        events: []
    });

    const {blogs, events} = formData;

    useEffect(() => {

        let heroImgWidth = window.innerWidth - document.querySelector( 'div.homepage-header-content' ).getBoundingClientRect().right;

        if ( heroImgWidth ) {

            document.querySelector( 'div.homepage-header-img' ).style.width = heroImgWidth + 'px';
            document.querySelector( 'div.homepage-header-about' ).style.width = heroImgWidth + 'px';

            function getWidthHero() {

    
                if ( $( 'div.homepage-header-content' ).length ) {

                    heroImgWidth = window.innerWidth - document.querySelector( 'div.homepage-header-content' ).getBoundingClientRect().right;   

                    document.querySelector( 'div.homepage-header-img' ).style.width = heroImgWidth + 'px';
                    document.querySelector( 'div.homepage-header-about' ).style.width = heroImgWidth + 'px';

                } else {

                    return;

                }
    
            }
    
            window.addEventListener('resize', getWidthHero);

        }

        axios.get(`${process.env.REACT_APP_API_URL}homepage`)
        .then(function (response) {

            const {formData, blogs, events} = response.data;
            setFormData (
                {
                    ...formData, blogs, events
                }
            )
        })
        .catch(function (error) {

            console.log(error);

        })

    }, []);

    return (
        <div className="homepage">

            <div className="homepage-header">

                <div className="wrapper">

                    <div className="homepage-header-follow">

                        <span>Urmărește-ne și pe</span>

                        <a href="https://www.instagram.com/tineretbujac/" target="_blank" rel="noopenner noreferrer"
                           className="simple-link">instagram</a>

                        <a href="https://www.facebook.com/groups/965109080215342" target="_blank"
                           rel="noopenner noreferrer" className="simple-link">facebook</a>

                    </div>

                    <div className="homepage-header-content">

                        <h1>Împreună suntem biserica lui Hristos</h1>

                        <Link to="/intrebari-si-propuneri" className="btn">Întrebări și Propuneri</Link>

                    </div>

                </div>

                <div className="homepage-header-img">

                    <img src={HeaderImg} alt="Tineret Bujac"/>

                </div>

                <div className="homepage-header-about">

                    <span>Luni 19:00</span>

                    <span>Biserica Penticostală Gloria Bujac</span>

                    <span>Strada Gloriei nr3-5</span>

                </div>


            </div>

            <div className="homepage-game">

                <img className="bg" src={BgImgGame} alt=""/>

                <div className="wrapper">

                    <div className="homepage-game-con">

                        <h2 className="title48">Revelion 2025</h2>

                        <p className="p16">
                            Pentru a te inscrie sa participi la revelion impreuna cu noi, te rugam sa te inregistrezi in acest formular pana la data de 24 Decembrie. In cazul in care vrei sa inscrii mai multe persoane, te rugam ca dupa ce ai completat formularul pentru o persoana sa trimiti un nou raspuns in care sa completezi formularul pentru urmatoarea persoana (se aplica si pentru sot/sotie/copii de la 14 ani in sus).
                        </p>

                    </div>

                    <div className="homepage-game-placeholder">
                        <iframe
                            src="https://docs.google.com/forms/d/e/1FAIpQLSdP6MiMPCz6QyY15Cv9bUJNY4GeVF-gl_q9XTFYBI9oB4E2lw/viewform"
                            width="640" height="542" frameBorder="0" marginHeight="0" marginWidth="0">Loading…
                        </iframe>
                    </div>

                </div>

            </div>

            <div className="homepage-about">

                <div className="wrapper">

                    <div className="herotext">

                        <h2 className="title48">Tineret Bujac</h2>

                        <p className="p20">Suntem o comunitate de tineri care Îl iubim pe Dumnezeu și dorim sa Îi fim
                            placuți. Întalnirile noastre au loc săptămânal, luni de la ora 19:00 în cartierul Bujac,
                            Arad (Strada Gloriei nr 3-5).</p>

                        <p className="p20">Mă veţi căuta, şi Mă veţi găsi dacă Mă veţi căuta cu toată inima. (Ieremia
                            29:13). La întalnirile noastre, căutăm să descoperim mai mult din Dumnezeu. Credem că nu cei
                            din față sunt cei care trebuie să ți-L descopere pe Dumnezeu, ci tu Îl vei găsi dacă Îl vei
                            căuta cu toata inima.</p>

                        <Link to="/despre-noi" className="link">Despre noi <Arrow/></Link>

                    </div>

                    <Swiper
                        loop={true}
                        loopAdditionalSlides={2}
                        watchSlidesProgress={true}
                        watchSlidesVisibility={true}
                        breakpoints={{

                            0: {
                                slidesPerView: 1,
                                spaceBetween: 10
                            },

                            360: {
                                slidesPerView: 2,
                                spaceBetween: 10
                            },

                            1150: {
                                slidesPerView: 2,
                                spaceBetween: 20
                            },

                            1250: {
                                slidesPerView: 3,
                                spaceBetween: 36
                            }
                        }}
                    >
                        <SwiperSlide><img src={About1} alt=""/></SwiperSlide>
                        <SwiperSlide><img src={About2} alt=""/></SwiperSlide>
                        <SwiperSlide><img src={About3} alt=""/></SwiperSlide>
                        <SwiperSlide><img src={About4} alt=""/></SwiperSlide>
                        <SwiperSlide><img src={About5} alt=""/></SwiperSlide>
                        <SwiperSlide><img src={About6} alt=""/></SwiperSlide>
                        <SwiperSlide><img src={About7} alt=""/></SwiperSlide>
                        <SwiperSlide><img src={About8} alt=""/></SwiperSlide>
                        <SwiperSlide><img src={About9} alt=""/></SwiperSlide>
                        <SwiperSlide><img src={About10} alt=""/></SwiperSlide>
                        <SwiperSlide><img src={About11} alt=""/></SwiperSlide>
                        <SwiperSlide><img src={About12} alt=""/></SwiperSlide>
                        <SwiperSlide><img src={About13} alt=""/></SwiperSlide>
                    </Swiper>

                </div>

            </div>

            <div className="homepage-isp wrapper">

                <div className="homepage-isp-img">

                    <img src={Ispimg} alt="Adauga o intrebare sau o propunere"/>

                </div>

                <div className="homepage-isp-main">

                    <div className="homepage-isp-main-i intrabari">

                        <h2 className="title34">Ce întrebări te preocupă ?</h2>

                        <p className="p18">Știm că ai întrebări, este ceva normal, fiecare dintre noi avem. Deoarece
                            formularul este unul anonim nu trebuie să îți faci griji pentru identitatea ta. Dorința
                            noastră este de a te ajuta să găsești răspunul din Scriptură.</p>

                        <Link to="/intrebari-si-propuneri" className="link">Adaugă o întrebare <Arrow/></Link>

                    </div>

                    <div className="homepage-isp-main-i propuneri">

                        <h2 className="title34">Ai o propunere pentru noi ?</h2>

                        <p className="p18">Într-o lume a feedback-ului ar trebui să fim ignoranți că noi să nu îl
                            primim. Te rugăm să ne lași câteva gânduri/propuneri pentru a îmbunătăți ceea ce se întâmplă
                            la tineret.</p>

                        <Link to="/intrebari-si-propuneri" className="link">Adaugă o propunere <Arrow/></Link>

                    </div>

                </div>

            </div>

            <div className="homepage-blog">

                <div className="wrapper">

                    <div className="homepage-blog-header hero">

                        <div className="hero-title">

                            <h2 className="title48">Ultimele articole din blogul nostru</h2>

                        </div>

                        <div className="hero-description">

                            <p className="p20">Ai un gând din partea Lui Dumnezeu așternut pe hârtie care crezi că ar
                                putea fi de ajutor pentru noi ? Transmite-ne lucrul acesta sau citește unul dintre
                                articolele existente.</p>

                            <Link to="/blog" className="link">Vezi toate articolele <Arrow/></Link>

                        </div>

                    </div>

                    <Swiper
                        loop={false}
                        loopAdditionalSlides={2}
                        watchSlidesProgress={true}
                        watchSlidesVisibility={true}
                        breakpoints={{

                            0: {
                                slidesPerView: 1.1,
                                spaceBetween: 10,
                            },

                            400: {
                                slidesPerView: 1.3,
                                spaceBetween: 20,
                            },

                            1024: {
                                slidesPerView: 2,
                                spaceBetween: 25
                            },

                            1150: {
                                slidesPerView: 2,
                                spaceBetween: 30
                            },

                            1250: {
                                slidesPerView: 3,
                                spaceBetween: 36
                            }
                        }}
                    >

                        {Object.keys(blogs).map((qs, i) => (

                            <SwiperSlide className="articledesign" key={i}>

                                <div className="articledesign-img">

                                    <Link to={`blog/${blogs[qs].slug}`}>

                                        <img src={blogs[qs].image} alt={blogs[qs].title}/>

                                    </Link>

                                </div>

                                <Link to={`blog/${blogs[qs].slug}`} className="articledesign-con">

                                    <h3>{blogs[qs].title}</h3>

                                    <p>{blogs[qs].description}</p>

                                    <span className="link">Vezi mai mult <Arrow/></span>

                                </Link>

                            </SwiperSlide>

                        ))}

                    </Swiper>

                </div>

            </div>

            <div className="homepage-game">

                <img className="bg" src={BgImgGame} alt=""/>

                <div className="wrapper">

                    <div className="homepage-game-con">

                        <h2 className="title48">Implică-te la Tineret</h2>

                        <p className="p16">
                            Credem că Dumnezeu este Cel care ne cheamă la slujire și ne capacitează pe fiecare dintre
                            noi pentru aceasta. Dacă vrei să faci mai mult pentru Dumnezeu sau simți că ești chemat la o
                            anumită slujire, la tineret ai ocazia să te implici și tu. Completează formularul pentru
                            a-ți începe slujirea.
                        </p>

                    </div>

                    <div className="homepage-game-placeholder">
                        <iframe
                            src="https://docs.google.com/forms/d/e/1FAIpQLSf7WHxfJdt6nRvJo5S6seoVRGlZ3jJwloc6jg01IgjDZo9gNA/viewform"
                            width="640" height="542" frameBorder="0" marginHeight="0" marginWidth="0">Loading…
                        </iframe>
                    </div>

                </div>

            </div>

            <div className="homepage-events">

                <div className="wrapper">

                    <div className="homepage-events-header hero">

                        <div className="hero-title">

                            <h2 className="title48">Cu ce ne mai ocupăm...</h2>

                        </div>

                        <div className="hero-description">

                            <p className="p20">Pentru că suntem o comunitate activă, avem diverse întâlniri, activități
                                sociale sau sportive prin care lucrăm la unitatea dintre noi. Ne-ar face plăcere să faci
                                și tu parte din urmatoarele întâlniri, dar până atunci poți urmări parte din întâlnirile
                                noastre.</p>

                            <Link to="/blog" className="link">Vezi toate evenimentele <Arrow/></Link>

                        </div>

                    </div>

                    <div className="homepage-events-items">

                        {Object.keys(events).map((qs, i) => (

                            <div className="homepage-events-items-i eventdesign" key={i}>

                                <span className="label-date">{events[qs].date}</span>

                                <img src={events[qs].image} alt={events[qs].title}/>

                                <h3 className="title40">{events[qs].title}</h3>

                                <p className="p14">{events[qs].description}</p>

                                <Link to={`evenimente/${events[qs].slug}`} className="simple-link">Vezi detalii</Link>

                            </div>

                        ))}

                    </div>

                </div>

            </div>

        </div>
    );
}

export default Homepage;